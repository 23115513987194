<template>
  <div class="works">
    <left-menu current="memberManagement"></left-menu>
    <div class="main">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>成员管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
      <div class="content">
        <div class="header">
          <div class="left">
            <div class="switch">
              <div @click="params.type = 1;" :class="{'active':params.type === 1}">
                已加入{{statistics.joined ? statistics.joined : '' }}
              </div>
              <div @click="params.type = 2;" :class="{'active':params.type === 2}">
                待审核{{statistics.toBeReviewed ? statistics.toBeReviewed : ''}}
              </div>
            </div>
            <div class="add-member" @click="addMemberFn">
              ＋添加成员
            </div>
          </div>
          <div class="right">
            <el-input prefix-icon="el-icon-search" v-model="params.value"  @keyup.enter.native="query" placeholder="请输入成员名称、姓名或手机号"></el-input>
          </div>
        </div>
        <el-table  fit  header-cell-class-name="header-table" :data="tableList" >
          <el-table-column
              width="30">
          </el-table-column>
          <el-table-column
              key="1"
              type="index"
              width="70"
              label="序号">
          </el-table-column>
          <el-table-column
              width="170"
              key="2"
              :label="params.type === 2 ? '申请人' : '成员名称'">
            <template v-slot:default="scope">
              <el-image fit="fit" style="width: 24px;height: 24px; vertical-align: middle;border-radius: 50px;" :src="scope.row.avatar"></el-image>
              {{scope.row.nickName}}
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              key="3"
              v-if="params.type === 1"
              label="姓名">
          </el-table-column>
          <el-table-column
              key="4"
              prop="phone"
              label="手机号">
          </el-table-column>
          <el-table-column
              width="120"
              key="5"
              v-if="params.type === 1"
              label="最后登录">
            <template v-slot:default="scope">
              {{moment(scope.row.lastLoginTime).fromNow()}}
            </template>
          </el-table-column>
          <el-table-column
              width="200"
              key="6"
              prop="roleName"
              v-if="params.type === 1"
              label="角色">
            <template  v-slot:default = "scope">
              <el-select v-if="scope.row.managerUser === 0" class="select" v-model="scope.row.rid" placeholder="请选择角色" @change="modifyRoleFn(scope.row)">
                <el-option
                    v-for="item in  roleList"
                    :key="item.id"
                    :disabled="item.type === 0"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div v-else style="height: 40px;line-height: 40px;">管理员</div>
            </template>
          </el-table-column>
          <el-table-column
              key="7"
              v-if="params.type === 2"
              label="申请加入时间">
            <template v-slot:default="scope">
              {{ moment(scope.row.submitTime).format('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column
              key="8"
              label="状态">
            <template v-slot:default="scope">
              {{enumerationType[scope.row.type]}}
            </template>
          </el-table-column>
          <el-table-column
              label="操作">
            <template v-slot:default = "scope">
              <el-button type="text" v-if="params.type === 2 && scope.row.type === 0" @click="publicObj = scope.row; operationShow = true;  operationType = 2">
                通过
              </el-button>
              <el-button type="text" v-if="params.type === 2 && scope.row.type === 0" @click="publicObj = scope.row; operationShow = true;  operationType = 3">
                拒绝
              </el-button>
              <el-button type="text" v-if="params.type === 1 && scope.row.managerUser !== 1"  @click="edit(scope.row)">
                编辑
              </el-button>
              <el-button type="text" v-if="scope.row.managerUser !== 1 && scope.row.type !== 0"  @click="publicObj = scope.row;  operationShow = true; operationType = 1">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right;margin-top: 10px">
          <el-pagination
              background
              layout="prev, pager, next"
              @current-change="getSongHistory"
              :page-size="params.pageSize"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <Hi-custom-popup wide="500px"  :show.sync="addMemberShow" :but-show="false" title="添加成员">
      <div class="share">
        <div class="title">
          请发送链接给需要邀请的成员，成员加入后，需公司管理员审批
        </div>
        <div style="display: flex;justify-content: space-between;">
          <el-input v-model="invitationCode"></el-input>
          <el-button  type="primary" @click="copyFn(invitationCode)">复制链接</el-button>
        </div>
        <div
            style="display: flex;justify-content: space-between;align-items: center;padding: 0 14px;box-sizing: border-box;">
          <div v-if="timeNum > 0">
            <span> 链接剩余有效时间：</span><span v-if="addMemberShow" style="color:orangered;">{{timeNum | dateNow}}</span>
          </div>
          <div v-else>
            <span>链接已失效</span>
          </div>
          <div style="cursor: pointer;" @click="type = 1; getInviteCode()">
            <el-image style="width: 20px;height: 20px;vertical-align: middle;font-size: 12px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;color: #666666;" :src="require('../../assets/regenerate-logo.png')"></el-image> <span style="vertical-align: middle;">
            重新生成
          </span>
          </div>
        </div>
      </div>
    </Hi-custom-popup>
    <hi-custom-popup :but-show="true" wide="700px" :show.sync="editShow" title="编辑" @confirm="confirm">
      <div style="margin-top: 40px;">
        <el-row>
          <el-col :span="4">
            <el-image :src="editForm.avatar "   style="width: 90px;height: 90px;">
              <div slot="error" class="image-slot">
               <el-image style="width: 90px;height: 90px;" :src="require('../../assets/artist-logo.png')"></el-image>
              </div>
            </el-image>
          </el-col>
          <el-col :span="12">
            <div style="font-size: 12px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: LEFT;color: #999999;">图片需为jpg、jpeg、png格式</div>
            <div style="margin-top: 30px;width: 80px;height: 30px;">
              <el-upload
                  :show-file-list="false"
                  accept="image/jpeg,image/png,image/jpg"
                :before-upload="beforeUpload"
                  :on-success="onSuccess"
                :action="uploadApi"
                :headers="{ ...headers, fileType: 'image' }"
              >
              <div style="width: 80px;height: 30px; line-height: 30px; background: #ffffff;border: 0.5px solid #dedede;border-radius: 4px;font-size: 12px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;color: #555555;text-align: center">点击上传</div>
            </el-upload></div>
          </el-col>
        </el-row>
        <el-form class="modify-form" inline style="margin-top: 25px;margin-bottom: 25px;">
            <el-form-item  label="昵称">
            <el-input v-model="editForm.nickName" placeholder="请输入用户名称"></el-input>
          </el-form-item>
          <el-form-item  label="姓名" class="el-form-item-name">
            <el-input v-model="editForm.name" placeholder="请输入用户姓名"></el-input>
          </el-form-item>
          <el-form-item  label="角色">
          <el-select v-model="editForm.rid" >
            <el-option v-for="item in roleList" :value="item.id" :key="item.id" :disabled="item.type === 0" :label="item.name"></el-option>
          </el-select>
          </el-form-item>
        </el-form>
      </div>
    </hi-custom-popup>
    <hi-custom-popup title="操作确认" but-wide="160px" wide="480px" :show.sync="operationShow" :but-show="true" @confirm="operationFn">
      <div style="margin: 55px 0;text-align: center;font-size: 14px;color: #333333;font-weight: 400;">
      {{title}}
      </div>
    </hi-custom-popup>
  </div>
</template>
<script>
import leftMenu from "@/components/LeftMenu";
import HiCustomPopup from "@/components/HiCustomPopup.vue";
import moment from 'moment';
import store from "@/store";
let enumerationType = {0:'待审核', 1:'审核通过', 2:'拒绝',3:'已加入其他机构'}
let timer;
export default {
  name: "member-management",
  components: {
    leftMenu,
    HiCustomPopup
  },
  mounted() {
  moment.locale('zh-cn')
  },
  data() {
    return {
      total:0,
      enumerationType,
      invitationShow: true,
      addMemberShow: false,
      editShow:false,
      invitationCode: undefined,
      timeNum: -1,
      moment,
      isInvitation: true,
      tableList: [],
      roleList:[],
      params: {
        type: 1,
        value: undefined,
        pageNum: 1,
        pageSize: 10
      },
      type:0,
      editForm:{
        avatar:undefined,
        id:undefined,
        name:undefined,
        nickName:undefined,
        rid:undefined,
        status:1,
      },
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      publicObj:null,
      operationShow:false,
      operationType:undefined, //1:删除2：通过3:拒绝
      title:undefined,
      statistics:{
        joined:0,
        toBeReviewed:0
      }
    }
  },
  created() {
    this.getList();
    this.$post('/api/company/role/list').then(r=>{
      if(r.code === 0){
        this.roleList = r.data;
      }
    })
  },
  methods: {
    edit(row){
      this.editShow = true;
      this.publicObj = row;
      this.editForm.avatar = this.publicObj.avatar;
      this.editForm.name = this.publicObj.name;
      this.editForm.nickName = this.publicObj.nickName;
      this.editForm.rid = this.publicObj.rid;
    },
    //分页
    getSongHistory(val){
      this.params.pageNum = val;
      this.getList()
    },
    modifyRoleFn(row){
      this.$post('/api/company/user/update',{rid:row.rid,id:row.id}).then(r=>{
        console.log(r,'角色修改成功');
        this.$store.dispatch('getRoleIds');
      })
    },
    //查询
    query(){
      this.params.pageNum = 1;
      this.getList();
    },
    addMemberFn() {
      this.addMemberShow = true;
      if (this.isInvitation) {
        this.getInviteCode();
      }
    },
    getInviteCode() {
      this.$get('/api/company/user/inviteCode?type=' + this.type).then(r => {
        console.log(process.env.VUE_APP_BASE_API, ' process.env.VUE_APP_BASE_API')
        let url = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development' ? 'http://musician-test.hikoon.com/#' : 'https://m.hikoon.com/#';
        this.invitationCode = url + '/invitation-details/' + r.inviteCode;
        this.timeNum = r.expireTime ;
        this.countDownFn();
        this.isInvitation = false;
      })
    },
    countDownFn() {
      clearInterval(timer);
      timer = setInterval(() => {
        if (this.timeNum > 0) {
          this.timeNum--;
        } else {
          this.isInvitation = true;
          clearInterval(timer);
        }
      }, 1000)
    },
    copyFn(val) {
      // createElement() 方法通过指定名称创建一个元素
      let copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        let copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$message.success("复制成功");
          this.addMemberShow = false;
        }
      } catch {
        this.$message.error("复制失败，请检查浏览器兼容");
      }
    },
    getList() {
      this.tableList = [];
      this.$post('/api/company/user/list', this.params).then(r => {
        this.tableList = r.data.list;
        this.total = r.data.total
        this.quantityStatistics()
      })
    },
    examine(id,type){
      this.$get(`/api/company/user/typeChange?id=${id}&type=${type}`).then(r=>{
        if(r.code === 0){
          this.$popup({msg: '操作成功', type:'success'})
        }
        this.getList()
      })
    },
    beforeUpload(file){
      console.log('上传前',file)
    },
    onSuccess(file){
      console.log(file,'file')
      if(file.code === 0){
        this.editForm.avatar = file.url;
      }
    },
    confirm(){
         this.editForm.id = this.publicObj.id
         this.$post('/api/company/user/update',this.editForm).then(r=>{
           if(r.code === 0){
             this.$popup({msg:'修改成功',type:'success'})
             this.editShow = false;
             this.getList();
           }
         })

    },
    operationFn(){
      if (this.operationType === 1){
        this.$post('/api/company/user/update',{id:this.publicObj.id,status: -1}).then(r=>{
          if(r.code === 0){
            this.getList()
          }
        })
      }else if(this.operationType === 2){
        this.examine(this.publicObj.id,1)
      }else {
        this.examine(this.publicObj.id,2)
      }
      this.operationShow = false
    },
    quantityStatistics(){
      this.$get('/api/company/role/listNum').then(
          r=>{
            this.statistics.joined = r.data.joinNum;
            this.statistics.toBeReviewed = r.data.notJoinNum;
          }
      )
    },
  },
  watch:{
    'params.type':function () {
       this.getList()
    },
    operationShow(val){
      if(!val)return
      if(this.operationType === 1){
        this.title =`请确认是否删除“${this.publicObj.nickName}”?`
      }else if(this.operationType === 2){
        this.title =`请确认是否通过申请人“${this.publicObj.nickName}”的加入?`
      }else {
        this.title =`请确认是拒绝申请人“${this.publicObj.nickName}”的加入?`
      }
    }
  },
  filters:{
    dateNow(value){
      let secondTime = parseInt(value);// 秒
      let minuteTime = 0;// 分
      let hourTime = 0;// 小时
      if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if(minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取余的分，获取分钟除以60取余的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + parseInt(secondTime) + "秒";

      if(minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
      }
      if(hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/works.scss';

.content {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .left {
      display: flex;
      .switch {
        width: 236px;
        height: 40px;
        background: #f4f4f4;
        border-radius: 2px;
        padding: 1px;
        display: flex;

        div {
          line-height: 40px;
          text-align: center;
          flex: 1;
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }

        .active {
          color: #FF004D;
          background: #ffffff;
          cursor: default;
        }
      }

      .add-member {
        margin-left: 24px;
        width: 122px;
        height: 40px;
        background: rgba(255, 0, 77, .1);
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ff004d;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }
    }

    .right {
      width: 290px;

      ::v-deep .el-input {
        box-sizing: border-box;

        .el-input__inner {
          height: 44px;
          border: 1px solid #e5e5e5;
          border-radius: 3px;
        }
      }
    }
  }
}

.share {
  padding-top: 39px;
  box-sizing: border-box;
   .title{
     font-size: 12px;
     font-family: PingFang SC, PingFang SC-Regular;
     font-weight: 400;
     text-align: LEFT;
     color: #999999;
     height: 17px;
     text-align: center;
   }
  > div {
    width: 420px;
    margin-bottom: 20px;
    height: 48px;

    ::v-deep .el-input {
      border: 1px solid  #eeeeee;
      padding: 0 10px;
      height: 38px;
      width: 308px;
      box-sizing: border-box;
      border-radius: 4px;

      .el-input__inner {
        height: 35px;
        border: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .el-button {
      height: 38px;
      border-radius: 3px;
    }
  }

  > div:nth-child(3) {
    background-color: #f7f7f7;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #999999;
  }
}
::v-deep .modify-form{
  overflow: hidden;
  .el-form-item{
    border-bottom: 1px solid #cccccc;
    height: 46px;
    width: 275px;
    margin-right: 50px;
    .el-form-item__content{
     float: right;
    }
    .el-input{
      float: right;
      margin-top: 6px;
      vertical-align: middle;
      .el-input__inner{
        border: none;
        text-align: right;
      }
    }
  }
  .el-form-item-name{
    margin-right: 0;
  }
  .el-form-item__label{
    margin-top: 6px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;
  }
}
 ::v-deep .select{
  .el-input__inner{
    padding: 0 10px;
    background-color: #f4f4f4;
    border: none;
    width: 142px;
  }
}
::v-deep .header-table {
  height: 48px;
  background: #f7f7f7 !important;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #555555;

  .cell {

  }

}
</style>
